export enum ComponentTypes {
  TextField = 'TextField',
  TextArea = 'TextArea',
  Password = 'Password',
  Toggle = 'Toggle',
  Select = 'Select',
  Group = 'Group',
  NestedGroup = 'NestedGroup',
  Array = 'Array',
  RichTextEditor = 'RichTextEditor',
  FileUpload = 'FileUpload',
  ColorPicker = 'ColorPicker',
  Checkbox = 'Checkbox'
}
